import * as Sentry from '@sentry/browser';

const element = document.head.querySelector("meta[name='sentry']")

let config = null
let user = null

if (element) {
  config = JSON.parse(window.atob(element.getAttribute("content")))

  user = config.user
  delete config.user
}

if (config && config.dsn) {
  Sentry.init(config)

  if (user) {
    Sentry.configureScope((scope) => {
      scope.setUser(user)
    })
  }
}
