import "../application/sentry"

// require("@rails/ujs").start()
// require("turbolinks").start()

// Images
require.context('../../images', true)

// CSS
import "pacifico.css"
import "application.sass"
